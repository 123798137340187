import { useEffect, useState, useMemo } from "react";
import useAuth from "../../hooks/useAuth";
import {
  Sliders,
  Briefcase,
  User,
  Calendar,
  Key,
  Users,
  Grid,
  CheckSquare,
  Map,
} from "react-feather";
import {
  Dashboard,
  DesktopWindows,
  RingVolume,
  Timeline,
  Code,
  QrCode,
  StarBorder,
  Settings,
  SmartToy,
  ContactSupport,
} from "@mui/icons-material";

const useWidgetItems = () => {
  const { isAuthenticated, isInitialized, user } = useAuth();
  const [navItems, setNavItems] = useState([]);

  // Move localStorage read into useMemo to prevent recreation on every render
  const savedPermissions = useMemo(() => {
    return JSON.parse(localStorage.getItem("rbacPermissions")) || [];
  }, []); // Empty dependency array since we only need to read this once

  useEffect(() => {
    if (!isInitialized) return;

    if (!isAuthenticated) {
      setNavItems([]);
      return;
    }

    if (user?.subscriptionLevel === "1") {
      setNavItems([
        {
          title: "Getting Started",
          pages: [
            {
              href: "/widget/dashboard",
              icon: Dashboard,
              title: "Dashboard",
            },
            {
              href: "/widget/visitors",
              icon: DesktopWindows,
              title: "Visitors",
            },
            {
              href: "/widget/incoming-requests",
              icon: RingVolume,
              title: "Incoming Requests",
            },
            {
              href: "/widget/lead-overview",
              icon: Timeline,
              title: "Lead Overview",
            },
            {
              href: "/widget/channel-connect",
              icon: Code,
              title: "Channel Connect",
            },
            {
              href: "/widget/qr-connect",
              icon: QrCode,
              title: "QR Connect",
            },
            {
              href: "/widget/support-tickets",
              icon: ContactSupport,
              title: "Support Tickets",
            },
            {
              href: "/widget/settings",
              icon: Settings,
              title: "Settings",
            },
          ],
        },
      ]);
      return;
    }

    // Move pages section creation into the effect
    const pagesSection = [
      {
        href: "settings",
        icon: Sliders,
        title: "Settings",
        children: [
          {
            href: "/business-settings",
            icon: Briefcase,
            title: "Business Settings",
          },
          {
            href: "/profile",
            icon: User,
            title: "Profile",
          },
          {
            href: "/availability",
            icon: Calendar,
            title: "Availability",
          },
          {
            href: "/change-password",
            icon: Key,
            title: "Change Password",
          },
        ],
      },
      savedPermissions.includes("VIEW_USERS") && {
        href: "/settings/users",
        icon: Users,
        title: "User Management",
      },
      savedPermissions.includes("VIEW_GROUPS") && {
        href: "/settings/groups",
        icon: Grid,
        title: "Groups",
      },
      savedPermissions.includes("VIEW_ROLES_PERMISSIONS") && {
        href: "/settings/roles-permissions",
        icon: CheckSquare,
        title: "Roles & Permissions",
      },
      savedPermissions.includes("VIEW_SPACE_MANAGEMENT") && {
        href: "/space-management",
        icon: Map,
        title: "Space Management",
      },
    ].filter(Boolean);

    setNavItems([
      {
        title: "Getting Started",
        pages: pagesSection,
      },
    ]);
  }, [isInitialized, isAuthenticated, user, savedPermissions]); // Dependencies are now stable

  return navItems;
};

export default useWidgetItems;
