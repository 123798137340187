import React, { useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { gql, useMutation } from "@apollo/client";
import { CircularProgress, Box, Typography } from "@mui/material";

const CONNECT_CALENDAR = gql`
  mutation ConnectCalendar($code: String!, $provider: String!) {
    connectCalendar(code: $code, provider: $provider) {
      id
      provider
      email
    }
  }
`;

const MicrosoftAuthCallback = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [connectCalendar] = useMutation(CONNECT_CALENDAR);

  useEffect(() => {
    const handleCallback = async () => {
      try {
        const searchParams = new URLSearchParams(location.search);
        const code = searchParams.get("code");

        if (!code) {
          console.error("No code received from Microsoft");
          navigate("/calendar/event-types");
          return;
        }

        console.log("Received auth code:", code);

        const result = await connectCalendar({
          variables: {
            code,
            provider: "microsoft",
          },
        });

        console.log("Connection result:", result);
        navigate("/calendar/event-types");
      } catch (error) {
        console.error("Error connecting calendar:", error);
        navigate("/calendar/event-types");
      }
    };

    handleCallback();
  }, [location, connectCalendar, navigate]);

  return (
    <Box
      display="flex"
      flexDirection="column"
      alignItems="center"
      justifyContent="center"
      minHeight="100vh"
    >
      <CircularProgress />
      <Typography sx={{ mt: 2 }}>
        Connecting your Microsoft Calendar...
      </Typography>
    </Box>
  );
};

export default MicrosoftAuthCallback;
