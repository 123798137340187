import { useEffect, useState, useMemo } from "react";
import useAuth from "../../hooks/useAuth";
import {
  Sliders,
  Briefcase,
  User,
  Calendar,
  Key,
  Users,
  Grid,
  CheckSquare,
  Map,
  Anchor,
} from "react-feather";

const useSettingsItems = () => {
  const { isAuthenticated, isInitialized, user } = useAuth();
  const [navItems, setNavItems] = useState([]);

  // Move savedPermissions inside useMemo to prevent recreation on every render
  const savedPermissions = useMemo(() => {
    return JSON.parse(localStorage.getItem("rbacPermissions")) || [];
  }, []); // Empty dependency array since we only need to load this once

  // Base settings items that are always available
  const baseSettingsChildren = useMemo(
    () => [
      {
        href: "/settings/profile",
        icon: User,
        title: "Profile",
      },
      {
        href: "/settings/change-password",
        icon: Key,
        title: "Change Password",
      },
    ],
    []
  );

  // Premium settings items for subscription level 1
  const premiumSettingsChildren = useMemo(
    () => [
      {
        href: "/settings/business-settings",
        icon: Briefcase,
        title: "Business Settings",
      },
      ...baseSettingsChildren,
      {
        href: "/settings/integrations",
        icon: Anchor,
        title: "Integrations",
      },
    ],
    [baseSettingsChildren]
  );

  // Regular settings items
  const regularSettingsChildren = useMemo(
    () => [
      {
        href: "/business-settings",
        icon: Briefcase,
        title: "Business Settings",
      },
      ...baseSettingsChildren,
      {
        href: "/availability",
        icon: Calendar,
        title: "Availability",
      },
    ],
    [baseSettingsChildren]
  );

  useEffect(() => {
    if (!isInitialized || !isAuthenticated) {
      setNavItems([]);
      return;
    }

    const isPremium = user?.subscriptionLevel === "1";

    const pagesSection = [
      {
        href: "settings",
        icon: Sliders,
        title: "Settings",
        children: isPremium ? premiumSettingsChildren : regularSettingsChildren,
      },
    ];

    // Only add permission-based items if premium or has permission
    if (isPremium || savedPermissions.includes("VIEW_USERS")) {
      pagesSection.push({
        href: "/settings/users",
        icon: Users,
        title: "User Management",
      });
    }

    if (isPremium || savedPermissions.includes("VIEW_GROUPS")) {
      pagesSection.push({
        href: "/settings/groups",
        icon: Grid,
        title: "Groups",
      });
    }

    if (isPremium || savedPermissions.includes("VIEW_ROLES_PERMISSIONS")) {
      pagesSection.push({
        href: "/settings/roles-permissions",
        icon: CheckSquare,
        title: "Roles & Permissions",
      });
    }

    if (isPremium || savedPermissions.includes("VIEW_SPACE_MANAGEMENT")) {
      pagesSection.push({
        href: "/settings/space-management",
        icon: Map,
        title: "Space Management",
      });
    }

    setNavItems([
      {
        title: "Getting Started",
        pages: pagesSection,
      },
    ]);
  }, [
    isInitialized,
    isAuthenticated,
    user,
    savedPermissions,
    premiumSettingsChildren,
    regularSettingsChildren,
  ]);

  return navItems;
};

export default useSettingsItems;
