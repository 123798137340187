import React, { useState, useEffect, useRef, useCallback } from "react";
import {
  useQuery,
  useMutation,
  useSubscription,
  useApolloClient,
  gql,
} from "@apollo/client";
import {
  List,
  ListItem,
  ListItemText,
  Button,
  Typography,
  Box,
  Badge,
  IconButton,
} from "@mui/material";
import StopIcon from "@mui/icons-material/Stop";
import NotificationsIcon from "@mui/icons-material/Notifications";
import MicIcon from "@mui/icons-material/Mic";
import MicOffIcon from "@mui/icons-material/MicOff";
import CoBrowsingView from "./CoBrowsingView";

const START_CO_BROWSE = gql`
  mutation StartCoBrowse($chatSessionId: ID!) {
    startCoBrowse(chatSessionId: $chatSessionId) {
      id
      chatSessionId
      status
      createdAt
    }
  }
`;

const SEND_CO_BROWSE_EVENT = gql`
  mutation SendCoBrowseEvent($sessionId: ID!, $type: String!, $data: JSON!) {
    sendCoBrowseEvent(sessionId: $sessionId, type: $type, data: $data)
  }
`;

const UPLOAD_CALL_RECORDING = gql`
  mutation UploadCallRecording($recording: Upload!, $sessionId: ID!) {
    uploadCallRecording(recording: $recording, sessionId: $sessionId) {
      success
      filePath
      error
    }
  }
`;

const END_CHAT_SESSION = gql`
  mutation EndChatSession($input: EndChatSessionInput!) {
    endChatSession(input: $input) {
      id
      status
    }
  }
`;

const GET_PENDING_SESSIONS = gql`
  query GetPendingChatSessions {
    getPendingChatSessions {
      id
      userId
      status
      createdAt
    }
  }
`;

const CHAT_SESSION_CREATED = gql`
  subscription {
    chatSessionCreated {
      id
      userId
      status
      createdAt
    }
  }
`;

const ACCEPT_CHAT_SESSION = gql`
  mutation AcceptChatSession($sessionId: ID!) {
    acceptChatSession(sessionId: $sessionId) {
      id
      status
      agentId
    }
  }
`;

const DECLINE_CHAT_SESSION = gql`
  mutation DeclineChatSession($sessionId: ID!) {
    declineChatSession(sessionId: $sessionId) {
      id
      status
    }
  }
`;

const GET_MESSAGES = gql`
  query GetMessages($sessionId: ID!) {
    getMessages(sessionId: $sessionId) {
      id
      sender
      content
      timestamp
    }
  }
`;

const MESSAGE_SENT = gql`
  subscription OnMessageSent($sessionId: ID!) {
    messageSent(sessionId: $sessionId) {
      id
      sender
      content
      timestamp
    }
  }
`;

const SEND_MESSAGE = gql`
  mutation SendMessage($sessionId: ID!, $content: String!) {
    sendMessage(sessionId: $sessionId, sender: "agent", content: $content) {
      id
    }
  }
`;

// Define the signaling mutations and subscriptions
const SEND_SIGNAL = gql`
  mutation SendSignal($sessionId: ID!, $sender: String!, $signalData: String!) {
    sendSignal(sessionId: $sessionId, sender: $sender, signalData: $signalData)
  }
`;

function getSupportedMimeType() {
  const possibleTypes = [
    "video/webm;codecs=vp9,opus",
    "video/webm;codecs=vp8,opus",
    "video/webm;codecs=vp8",
    "video/webm;codecs=h264,opus",
    "video/webm;codecs=h264",
    "video/webm",
    "video/mp4",
    "audio/webm;codecs=opus",
    "audio/ogg;codecs=opus",
  ];

  for (const mimeType of possibleTypes) {
    if (MediaRecorder.isTypeSupported(mimeType)) {
      console.log(`Supported mimeType found: ${mimeType}`);
      return mimeType;
    }
  }

  console.error("No supported mimeType found for MediaRecorder");
  return null;
}

function IncomingRequests() {
  const [isCoBrowsing, setIsCoBrowsing] = useState(false);
  const [selectedSession, setSelectedSession] = useState(null);
  const [messageInput, setMessageInput] = useState("");
  const [notifications, setNotifications] = useState([]);
  const [pendingSessions, setPendingSessions] = useState([]);
  const [peerConnection, setPeerConnection] = useState(null);
  const [localStream, setLocalStream] = useState(null);
  const [videoCallActive, setVideoCallActive] = useState(false);
  const [isMuted, setIsMuted] = useState(false);
  const localVideoRef = useRef(null);
  const remoteVideoRef = useRef(null);
  const [mediaRecorder, setMediaRecorder] = useState(null);
  // const [isCallRecordingEnabled, setIsCallRecordingEnabled] = useState(true); // Control flag for recording
  const isCallRecordingEnabled = true; // Just use as a constant if we're not changing it
  const recordedChunks = useRef([]);
  const [endChatSession] = useMutation(END_CHAT_SESSION);
  const [uploadCallRecordingMutation] = useMutation(UPLOAD_CALL_RECORDING);

  const [startCoBrowse] = useMutation(START_CO_BROWSE);

  const client = useApolloClient();

  const handleStartCoBrowsing = async () => {
    if (!selectedSession) {
      console.error("No session selected");
      return;
    }

    try {
      const response = await startCoBrowse({
        variables: {
          chatSessionId: selectedSession.id, // Make sure this matches the schema
        },
      });

      // Send initialization event
      await client.mutate({
        mutation: SEND_CO_BROWSE_EVENT,
        variables: {
          sessionId: selectedSession.id,
          type: "co_browse_start",
          data: { initiated: true },
        },
      });
      setIsCoBrowsing(true);
    } catch (error) {
      console.error("Error starting co-browse:", error);
      console.error("Error details:", error.graphQLErrors);
      console.error("Network error:", error.networkError);
    }
  };

  const handleEndCoBrowsing = () => {
    setIsCoBrowsing(false);
  };

  const handleEndChat = async () => {
    if (!selectedSession) return;

    try {
      // Stop recording if active
      stopRecording();

      // Wait a bit for the recordingCompleted handler to fire
      await new Promise((resolve) => setTimeout(resolve, 500));

      // Cleanup streams
      cleanupStreams();

      // Reset video state
      setVideoCallActive(false);

      // End chat session in backend
      await endChatSession({
        variables: {
          input: {
            chatSessionId: selectedSession.id,
          },
        },
      });

      // Clear selected session
      setSelectedSession(null);

      // Reset states
      setMessages([]);
      setMessageInput("");
    } catch (error) {
      console.error("Error ending chat:", error);
    }
  };

  // Add function to handle recording
  const startCallRecording = (stream) => {
    if (!isCallRecordingEnabled || !selectedSession) return; // Check for selected session

    try {
      // Create a new stream that includes both local and remote tracks
      const audioTracks = stream.getAudioTracks();
      const videoTracks = stream.getVideoTracks();

      // Combine all tracks into a single stream
      const combinedStream = new MediaStream([...audioTracks, ...videoTracks]);

      // Get a supported mimeType
      const mimeType = getSupportedMimeType();
      if (!mimeType) {
        console.error("No supported mimeType found for MediaRecorder");
        return;
      }

      // Create MediaRecorder instance
      const recorder = new MediaRecorder(combinedStream, { mimeType });

      recorder.ondataavailable = (event) => {
        if (event.data.size > 0) {
          recordedChunks.current.push(event.data);
        }
      };

      recorder.onstop = () => {
        const blob = new Blob(recordedChunks.current, {
          type: recorder.mimeType,
        });

        // Use selectedSession.id for the upload
        uploadRecording(blob, selectedSession.id);

        // Clear the chunks for next recording
        recordedChunks.current = [];
      };

      // Start recording
      recorder.start(1000); // Create chunks every second
      setMediaRecorder(recorder);
    } catch (error) {
      console.error("Error starting recording:", error);
    }
  };

  // Modified upload function to use the session ID parameter
  const uploadRecording = async (blob, sessionId) => {
    console.log("!!!!!!!!!!!!!!!! uploading");
    if (!sessionId) {
      console.error("No session ID available for upload");
      return;
    }

    try {
      const fileExtension = blob.type.includes("mp4") ? "mp4" : "webm";
      const filename = `call-recording-${sessionId}.${fileExtension}`;
      const file = new File([blob], filename, { type: blob.type });

      await uploadCallRecordingMutation({
        variables: {
          recording: file,
          sessionId,
        },
      });

      console.log("Recording uploaded successfully");
    } catch (error) {
      console.error("Error uploading recording:", error);
    }
  };
  // Function to start video call
  const startVideoCall = () => {
    setVideoCallActive(true);

    // Get local media stream
    navigator.mediaDevices
      .getUserMedia({ video: true, audio: true })
      .then((stream) => {
        setLocalStream(stream);
        localVideoRef.current.srcObject = stream;

        // Initialize peer connection
        const pc = createPeerConnection();
        setPeerConnection(pc);

        // Add local stream tracks to peer connection
        stream.getTracks().forEach((track) => {
          pc.addTrack(track, stream);
        });

        // If recording is enabled, start recording when both streams are available
        if (isCallRecordingEnabled && selectedSession) {
          const combinedTracks = [];

          // Add local tracks
          stream.getTracks().forEach((track) => combinedTracks.push(track));

          // Create a new MediaStream with all tracks
          const recordingStream = new MediaStream(combinedTracks);
          startCallRecording(recordingStream);
        }

        // Send 'start-call' signal to user
        sendSignal("start-call", {});

        // Create and send offer
        pc.createOffer()
          .then((offer) => pc.setLocalDescription(offer))
          .then(() => {
            sendSignal("offer", pc.localDescription);
          });
      })
      .catch((error) => {
        console.error("Error accessing media devices.", error);
      });
  };

  const stopVideoCall = async () => {
    try {
      // Stop recording and streams as before
      stopRecording();

      if (localStream) {
        localStream.getTracks().forEach((track) => track.stop());
        setLocalStream(null);
      }

      if (peerConnection) {
        peerConnection.close();
        setPeerConnection(null);
      }

      // Reset video elements
      if (localVideoRef.current) {
        localVideoRef.current.srcObject = null;
      }
      if (remoteVideoRef.current) {
        remoteVideoRef.current.srcObject = null;
      }

      // Notify the client that the video call has ended
      if (selectedSession) {
        await sendSignal("video-ended", {
          message: "Agent ended the video call",
        });
      }

      // Reset video call state
      setVideoCallActive(false);
    } catch (error) {
      console.error("Error stopping video call:", error);
    }
  };

  // Function to mute/unmute microphone
  const toggleMute = () => {
    if (localStream) {
      localStream.getAudioTracks()[0].enabled = isMuted;
      setIsMuted(!isMuted);
    }
  };

  // Adjusted video container styling and positioning
  const videoContainerStyles = {
    display: videoCallActive ? "block" : "none",
    position: "absolute",
    top: "100px",
    right: "10px",
    width: "350px",
    height: "250px",
    backgroundColor: "black",
    zIndex: 1000,
    borderRadius: "8px",
    overflow: "hidden",
  };

  // Function to create peer connection
  const createPeerConnection = () => {
    const configuration = {
      iceServers: [{ urls: "stun:stun.l.google.com:19302" }],
    };

    const pc = new RTCPeerConnection(configuration);

    // Handle remote stream
    pc.ontrack = (event) => {
      if (remoteVideoRef.current.srcObject !== event.streams[0]) {
        remoteVideoRef.current.srcObject = event.streams[0];

        // If recording is already started, add remote tracks to the recording stream
        if (mediaRecorder && mediaRecorder.state === "recording") {
          const recordingStream = mediaRecorder.stream;
          event.streams[0].getTracks().forEach((track) => {
            recordingStream.addTrack(track);
          });
        }
      }
    };

    // Handle ICE candidates
    pc.onicecandidate = (event) => {
      if (event.candidate) {
        sendSignal("candidate", event.candidate);
      }
    };

    return pc;
  };

  // Add cleanup function for recording
  const stopRecording = useCallback(() => {
    if (mediaRecorder && mediaRecorder.state === "recording") {
      mediaRecorder.stop();
    }
  }, [mediaRecorder]);

  // Function to cleanup media streams
  const cleanupStreams = useCallback(() => {
    if (localStream) {
      localStream.getTracks().forEach((track) => track.stop());
    }
    if (peerConnection) {
      peerConnection.close();
    }
  }, [localStream, peerConnection]);

  // Fixed cleanup effect with all dependencies
  useEffect(() => {
    return () => {
      stopRecording();
      cleanupStreams();
    };
  }, [stopRecording, cleanupStreams]);

  // Fixed session change effect
  useEffect(() => {
    if (!selectedSession) {
      stopRecording();
    }
  }, [selectedSession, stopRecording]);

  const SIGNAL_RECEIVED = gql`
    subscription SignalReceived($sessionId: ID!) {
      signalReceived(sessionId: $sessionId) {
        sender
        signalData
      }
    }
  `;

  // Function to send signaling data
  const sendSignal = (type, data) => {
    client.mutate({
      mutation: SEND_SIGNAL,
      variables: {
        sessionId: selectedSession.id,
        sender: "agent",
        signalData: btoa(JSON.stringify({ type, data })),
      },
    });
  };

  // Subscribe to signaling data
  useSubscription(SIGNAL_RECEIVED, {
    variables: { sessionId: selectedSession ? selectedSession.id : "" },
    skip: !selectedSession,
    onSubscriptionData: ({ subscriptionData }) => {
      const { sender, signalData } = subscriptionData.data.signalReceived;
      if (sender === "user") {
        handleSignal(JSON.parse(atob(signalData)));
      }
    },
  });

  // Function to handle received signaling data
  const handleSignal = ({ type, data }) => {
    switch (type) {
      case "offer":
        // Create peer connection if not exists
        if (!peerConnection) {
          const pc = createPeerConnection();
          setPeerConnection(pc);
        }

        // Set remote description
        peerConnection
          .setRemoteDescription(new RTCSessionDescription(data))
          .then(() => {
            // Get local media
            return navigator.mediaDevices.getUserMedia({
              video: true,
              audio: true,
            });
          })
          .then((stream) => {
            setLocalStream(stream);
            localVideoRef.current.srcObject = stream;

            // Add local tracks
            stream.getTracks().forEach((track) => {
              peerConnection.addTrack(track, stream);
            });

            // Create and send answer
            return peerConnection.createAnswer();
          })
          .then((answer) => {
            return peerConnection.setLocalDescription(answer);
          })
          .then(() => {
            sendSignal("answer", peerConnection.localDescription);
          });
        break;

      case "answer":
        peerConnection.setRemoteDescription(new RTCSessionDescription(data));
        break;

      case "candidate":
        peerConnection.addIceCandidate(new RTCIceCandidate(data));
        break;

      case "video-ended":
        // Clean up video elements and streams
        if (peerConnection) {
          peerConnection.close();
          setPeerConnection(null);
        }
        if (localStream) {
          localStream.getTracks().forEach((track) => track.stop());
          setLocalStream(null);
        }

        // Reset video elements
        if (localVideoRef.current) {
          localVideoRef.current.srcObject = null;
        }
        if (remoteVideoRef.current) {
          remoteVideoRef.current.srcObject = null;
        }

        // Reset video call state
        setVideoCallActive(false);

        // Add system message
        setMessages((prev) => [
          ...prev,
          {
            id: Date.now(),
            sender: "System",
            content: "User ended the video call",
            timestamp: new Date().toISOString(),
          },
        ]);
        break;

      default:
        break;
    }
  };

  // Fetch initial pending sessions
  const { loading, error, data } = useQuery(GET_PENDING_SESSIONS);

  useEffect(() => {
    if (data && data.getPendingChatSessions) {
      setPendingSessions(data.getPendingChatSessions);
    }
  }, [data]);

  // Subscribe to new chat sessions
  useSubscription(CHAT_SESSION_CREATED, {
    onSubscriptionData: ({ subscriptionData }) => {
      const newSession = subscriptionData.data.chatSessionCreated;
      setPendingSessions((prev) => [newSession, ...prev]);
      setNotifications((prev) => [...prev, newSession]);
    },
  });

  const [acceptChatSession] = useMutation(ACCEPT_CHAT_SESSION);
  const [declineChatSession] = useMutation(DECLINE_CHAT_SESSION);
  const [sendMessage] = useMutation(SEND_MESSAGE);

  const handleAccept = (session) => {
    acceptChatSession({ variables: { sessionId: session.id } });
    setSelectedSession(session);
    setNotifications((prev) => prev.filter((n) => n.id !== session.id));
  };

  const handleDecline = (session) => {
    declineChatSession({ variables: { sessionId: session.id } });
    setNotifications((prev) => prev.filter((n) => n.id !== session.id));
  };

  // Handle messages
  const [messages, setMessages] = useState([]);

  const { data: messagesData } = useQuery(GET_MESSAGES, {
    variables: { sessionId: selectedSession ? selectedSession.id : "" },
    skip: !selectedSession,
  });

  useEffect(() => {
    if (messagesData && messagesData.getMessages) {
      setMessages(messagesData.getMessages);
    }
  }, [messagesData]);

  useSubscription(MESSAGE_SENT, {
    variables: { sessionId: selectedSession ? selectedSession.id : "" },
    skip: !selectedSession,
    onSubscriptionData: ({ subscriptionData }) => {
      const newMessage = subscriptionData.data.messageSent;
      setMessages((prev) => [...prev, newMessage]);
    },
  });

  const handleSendMessage = () => {
    if (!messageInput.trim() || !selectedSession) return;
    sendMessage({
      variables: {
        sessionId: selectedSession.id,
        content: messageInput.trim(),
      },
    });
    setMessageInput("");
  };

  if (loading) return <Typography>Loading...</Typography>;
  if (error) return <Typography>Error loading sessions</Typography>;

  return (
    <Box display="flex" height="100vh">
      {/* Left Sidebar */}
      <Box width="300px" borderRight="1px solid #ccc">
        <Box display="flex" alignItems="center" p={2}>
          <Typography variant="h6">Incoming Requests</Typography>
          <Badge badgeContent={notifications.length} color="error">
            <NotificationsIcon />
          </Badge>
        </Box>
        <List>
          {pendingSessions.map((session) => (
            <ListItem
              key={session.id}
              button
              onClick={() => setSelectedSession(session)}
            >
              <ListItemText
                primary={`User: ${session.userId}`}
                secondary={`Status: ${session.status}`}
              />
              {session.status === "pending" && (
                <>
                  <Button onClick={() => handleAccept(session)} color="primary">
                    Accept
                  </Button>
                  <Button
                    onClick={() => handleDecline(session)}
                    color="secondary"
                  >
                    Decline
                  </Button>
                </>
              )}
            </ListItem>
          ))}
        </List>
      </Box>
      {/* Right Content */}
      <Box flexGrow={1} p={2}>
        {selectedSession ? (
          <>
            <Typography variant="h6">
              Chat with User: {selectedSession.userId}
            </Typography>
            <Button
              variant="contained"
              color="primary"
              onClick={startVideoCall}
              style={{ marginBottom: "10px" }}
            >
              Start Video Call
            </Button>
            <Button
              variant="contained"
              color="error"
              onClick={handleEndChat}
              style={{ marginBottom: "10px" }}
            >
              End Chat
            </Button>
            <Button
              variant="contained"
              color="primary"
              onClick={handleStartCoBrowsing}
              style={{ marginBottom: "10px", marginLeft: "10px" }}
            >
              Start Co-browsing
            </Button>
            <Box id="videoContainer" style={videoContainerStyles}>
              <video
                id="remoteVideo"
                autoPlay
                playsInline
                style={{ width: "100%", height: "100%" }}
                ref={remoteVideoRef}
              ></video>
              <video
                id="localVideo"
                autoPlay
                muted
                playsInline
                style={{
                  position: "absolute",
                  bottom: "10px",
                  right: "10px",
                  width: "60px",
                  height: "45px",
                  border: "2px solid white",
                }}
                ref={localVideoRef}
              ></video>
              <Box
                style={{
                  position: "absolute",
                  top: "5px",
                  left: "5px",
                  display: "flex",
                  gap: "5px",
                }}
              >
                {/* Mute button */}
                <IconButton
                  onClick={toggleMute}
                  style={{
                    color: "white",
                    backgroundColor: "rgba(0, 0, 0, 0.5)",
                  }}
                >
                  {isMuted ? <MicOffIcon /> : <MicIcon />}
                </IconButton>
                {/* Stop Video Call button */}
                <IconButton
                  onClick={stopVideoCall}
                  style={{
                    color: "white",
                    backgroundColor: "rgba(0, 0, 0, 0.5)",
                  }}
                >
                  <StopIcon />
                </IconButton>
              </Box>
            </Box>
            <Box
              height="70vh"
              border="1px solid #ccc"
              borderRadius="4px"
              overflow="auto"
              p={2}
            >
              {messages.map((msg) => (
                <Box
                  key={msg.id}
                  display="flex"
                  justifyContent={
                    msg.sender === "agent" ? "flex-end" : "flex-start"
                  }
                  mb={1}
                >
                  <Box
                    p={1}
                    bgcolor={msg.sender === "agent" ? "#e0f7fa" : "#f1f1f1"}
                    borderRadius="4px"
                    maxWidth="70%"
                  >
                    <Typography variant="body2">{msg.content}</Typography>
                    <Typography variant="caption">
                      {new Date(msg.timestamp).toLocaleTimeString()}
                    </Typography>
                  </Box>
                </Box>
              ))}
            </Box>
            <Box display="flex" mt={2}>
              <input
                type="text"
                placeholder="Type a message..."
                value={messageInput}
                onChange={(e) => setMessageInput(e.target.value)}
                style={{ flexGrow: 1, padding: "8px" }}
              />
              <Button
                onClick={handleSendMessage}
                variant="contained"
                color="primary"
              >
                Send
              </Button>
            </Box>
            {isCoBrowsing && (
              <Box
                sx={{
                  position: "fixed",
                  top: "50%",
                  left: "50%",
                  transform: "translate(-50%, -50%)",
                  width: "80vw",
                  height: "80vh",
                  bgcolor: "background.paper",
                  boxShadow: 24,
                  p: 2,
                  zIndex: 1000,
                }}
              >
                <CoBrowsingView
                  sessionId={selectedSession?.id}
                  onEnd={handleEndCoBrowsing}
                />
              </Box>
            )}
          </>
        ) : (
          <Typography>Select a session to start chatting</Typography>
        )}
      </Box>
    </Box>
  );
}

export default IncomingRequests;
