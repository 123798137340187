import React, { useEffect, useRef, useState } from "react";
import { Box, IconButton } from "@mui/material";
import HighlightIcon from "@mui/icons-material/Highlight";
import StopIcon from "@mui/icons-material/Stop";
import { useSubscription, useMutation, gql } from "@apollo/client";

const CO_BROWSE_SUBSCRIPTION = gql`
  subscription CoBrowseEvent($sessionId: ID!) {
    coBrowseEvent(sessionId: $sessionId) {
      sessionId
      type
      data
      timestamp
    }
  }
`;

const SEND_CO_BROWSE_EVENT = gql`
  mutation SendCoBrowseEvent($sessionId: ID!, $type: String!, $data: JSON!) {
    sendCoBrowseEvent(sessionId: $sessionId, type: $type, data: $data)
  }
`;

const CoBrowsingView = ({ sessionId, onEnd }) => {
  const iframeRef = useRef(null);
  const [isInitialized, setIsInitialized] = useState(false);
  const [sendCoBrowseEvent] = useMutation(SEND_CO_BROWSE_EVENT);

  // Updated subscription handling
  const { data, loading, error } = useSubscription(CO_BROWSE_SUBSCRIPTION, {
    variables: { sessionId },
    onSubscriptionData: ({ subscriptionData }) => {
      if (subscriptionData?.data?.coBrowseEvent) {
        const event = subscriptionData.data.coBrowseEvent;
        console.log("Received co-browse event:", event);

        const iframeWindow = iframeRef.current?.contentWindow;
        if (iframeWindow) {
          try {
            iframeWindow.postMessage(
              {
                type: event.type,
                data: event.data,
                sessionId: event.sessionId,
                timestamp: event.timestamp,
              },
              "*"
            );
          } catch (error) {
            console.error("Error posting message to iframe:", error);
          }
        }
      }
    },
    onError: (error) => {
      console.error("Subscription error:", error);
    },
  });

  // Initialize co-browsing
  useEffect(() => {
    if (sessionId && !isInitialized) {
      console.log("Initializing co-browsing for session:", sessionId);
      sendCoBrowseEvent({
        variables: {
          sessionId,
          type: "co_browse_init",
          data: {
            timestamp: new Date().toISOString(),
            initialized: true,
          },
        },
      })
        .then(() => {
          setIsInitialized(true);
          console.log("Co-browsing initialized");
        })
        .catch((error) => {
          console.error("Error initializing co-browsing:", error);
        });
    }
  }, [sessionId, isInitialized, sendCoBrowseEvent]);

  // Handle iframe ready message
  useEffect(() => {
    const handleMessage = (event) => {
      if (event.data?.type === "iframe_ready") {
        console.log("Iframe ready, sending initial state request");
        sendCoBrowseEvent({
          variables: {
            sessionId,
            type: "request_initial_state",
            data: {
              timestamp: new Date().toISOString(),
            },
          },
        }).catch((error) => {
          console.error("Error requesting initial state:", error);
        });
      }
    };

    window.addEventListener("message", handleMessage);
    return () => window.removeEventListener("message", handleMessage);
  }, [sessionId, sendCoBrowseEvent]);

  // Cleanup on unmount
  useEffect(() => {
    return () => {
      if (sessionId && isInitialized) {
        sendCoBrowseEvent({
          variables: {
            sessionId,
            type: "co_browse_end",
            data: {
              timestamp: new Date().toISOString(),
            },
          },
        }).catch((error) => {
          console.error("Error ending co-browsing:", error);
        });
      }
    };
  }, [sessionId, isInitialized, sendCoBrowseEvent]);

  if (loading) return <div>Loading co-browsing...</div>;
  if (error) return <div>Error: {error.message}</div>;

  return (
    <Box sx={{ position: "relative", width: "100%", height: "100%" }}>
      <Box sx={{ position: "absolute", top: 10, right: 10, zIndex: 1 }}>
        <IconButton onClick={onEnd} color="error">
          <StopIcon />
        </IconButton>
      </Box>
      <iframe
        ref={iframeRef}
        style={{
          width: "90%",
          height: "100%",
          marginLeft: 150,
          border: "1px solid #000",
        }}
        src="http://localhost:5500/chat.html"
        sandbox="allow-scripts allow-same-origin allow-forms"
        title="Co-browsing View"
      />
    </Box>
  );
};

export default CoBrowsingView;
