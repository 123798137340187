import React, { useState, useEffect } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import { axiosInstance } from "../../utils/axios";
import { useNavigate } from "react-router-dom";
import { NavLink } from "react-router-dom";
import { spacing } from "@mui/system";
import styled from "@emotion/styled";
import { ArrowBack as ArrowBackIcon } from "@mui/icons-material";
import {
  Breadcrumbs as MuiBreadcrumbs,
  Grid,
  Typography,
  Button,
  Card as MuiCard,
  CardContent,
  Divider as MuiDivider,
  TextField as MuiTextField,
  MenuItem,
  Select,
  FormControl,
  InputLabel,
  Link,
  Snackbar,
  Alert as MuiAlert,
} from "@mui/material";
import { DatePicker, TimePicker } from "@mui/x-date-pickers";
import { Helmet } from "react-helmet-async";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";

import { Paper } from "@mui/material";
const Breadcrumbs = styled(MuiBreadcrumbs)(spacing);
const Divider = styled(MuiDivider)(spacing);

const Alert = MuiAlert;

// Add query to get calendar connections
const GET_CALENDAR_CONNECTIONS = `
  query GetCalendarConnections {
    getCalendarConnections {
      id
      provider
      email
      isDefault
      connected
      lastSynced
    }
  }
`;

const SYNC_CALENDAR_EVENT = `
  mutation SyncCalendarEvent($eventId: ID!, $connectionId: ID!) {
    syncCalendarEvent(eventId: $eventId, connectionId: $connectionId) {
      id
      providerId
      status
    }
  }
`;

const CREATE_EVENT_MUTATION = `
  mutation CreateEvent($input: EventInput!) {
    createEvent(input: $input) {
      id
      name
      startDate
      endDate
      startTime
      endTime
      timezone
      about
      organizedBy
      supportEmail
    }
  }
`;

const CREATE_TYPE_MUTATION = `
  mutation CreateAttendeeType($input: CreateAttendeeTypeInput!) {
    createAttendeeType(input: $input) {
      id
      name
    }
  }
`;

const validationSchema = Yup.object({
  name: Yup.string()
    .required("Event name is required")
    .min(1, "Event name cannot be empty")
    .matches(/\S/, "Event name cannot be only spaces"),
  startDate: Yup.date().required("Start date is required"),
  endDate: Yup.date()
    .required("End date is required")
    .min(Yup.ref("startDate"), "End date must be equal to or after start date"),
  startTime: Yup.date().required("Start time is required"),
  endTime: Yup.date()
    .required("End time is required")
    .test(
      "is-after-start",
      "End time must be after start time",
      function (endTime) {
        const { startDate, endDate, startTime } = this.parent;

        if (!startDate || !endDate || !startTime || !endTime) return true;

        const start = new Date(startTime);
        const end = new Date(endTime);

        // If dates are different, time comparison not needed
        if (startDate.getTime() !== endDate.getTime()) return true;

        // For same day events, ensure end time is after start time
        return end.getTime() > start.getTime();
      }
    ),
  timezone: Yup.string()
    .required("Timezone is required")
    .min(1, "Timezone cannot be empty")
    .matches(/\S/, "Timezone cannot be only spaces"),
  about: Yup.string()
    .required("About is required")
    .min(1, "About cannot be empty")
    .matches(/\S/, "About cannot be only spaces"),
  organizedBy: Yup.string()
    .required("Organizer name is required")
    .min(1, "Organizer name cannot be empty")
    .matches(/\S/, "Organizer name cannot be only spaces"),
  supportEmail: Yup.string()
    .email("Invalid email")
    .required("Email is required")
    .min(1, "Email cannot be empty")
    .matches(/\S/, "Email cannot be only spaces"),
  calendar: Yup.string()
    .required("Calendar is required")
    .min(1, "Calendar cannot be empty")
    .matches(/\S/, "Calendar cannot be only spaces"),
});

const timezones = [
  { label: "(GMT-12:00) International Date Line West", value: "Etc/GMT+12" },
  { label: "(GMT-11:00) Midway Island, Samoa", value: "Pacific/Midway" },
  { label: "(GMT-10:00) Hawaii", value: "Pacific/Honolulu" },
  { label: "(GMT-09:00) Alaska", value: "America/Anchorage" },
  {
    label: "(GMT-08:00) Pacific Time (US & Canada)",
    value: "America/Los_Angeles",
  },
  { label: "(GMT-07:00) Mountain Time (US & Canada)", value: "America/Denver" },
  { label: "(GMT-06:00) Central Time (US & Canada)", value: "America/Chicago" },
  {
    label: "(GMT-05:00) Eastern Time (US & Canada)",
    value: "America/New_York",
  },
  { label: "(GMT-04:00) Atlantic Time (Canada)", value: "America/Halifax" },
  { label: "(GMT-03:00) Brasilia", value: "America/Sao_Paulo" },
  { label: "(GMT-02:00) Mid-Atlantic", value: "Atlantic/South_Georgia" },
  { label: "(GMT-01:00) Cape Verde Is.", value: "Atlantic/Cape_Verde" },
  { label: "(GMT+00:00) London, Dublin", value: "Europe/London" },
  { label: "(GMT+01:00) Paris, Berlin, Rome", value: "Europe/Paris" },
  { label: "(GMT+02:00) Cairo, Athens", value: "Europe/Athens" },
  { label: "(GMT+03:00) Moscow, Baghdad", value: "Europe/Moscow" },
  { label: "(GMT+04:00) Dubai, Baku", value: "Asia/Dubai" },
  { label: "(GMT+05:00) Karachi", value: "Asia/Karachi" },
  { label: "(GMT+05:30) Mumbai, Colombo", value: "Asia/Kolkata" },
  { label: "(GMT+06:00) Dhaka", value: "Asia/Dhaka" },
  { label: "(GMT+07:00) Bangkok, Jakarta", value: "Asia/Bangkok" },
  { label: "(GMT+08:00) Hong Kong, Singapore", value: "Asia/Singapore" },
  { label: "(GMT+09:00) Tokyo, Seoul", value: "Asia/Tokyo" },
  { label: "(GMT+10:00) Sydney, Melbourne", value: "Australia/Sydney" },
  { label: "(GMT+11:00) Solomon Is.", value: "Pacific/Guadalcanal" },
  { label: "(GMT+12:00) Auckland", value: "Pacific/Auckland" },
];

const EventEntryForm = () => {
  const navigate = useNavigate(); // useNavigate for redirection
  const [alert, setAlert] = useState({
    open: false,
    message: "",
    severity: "success",
  });
  const [defaultCalendar, setDefaultCalendar] = useState(null);

  // Initialize with empty array and loading state
  const [calendarConnections, setCalendarConnections] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  // Clear any existing error when component mounts
  useEffect(() => {
    setError(null);
    return () => {
      setCalendarConnections([]);
      setError(null);
    };
  }, []);

  // Fetch calendar connections
  // Fetch calendar connections
  // Fetch calendar connections
  useEffect(() => {
    const fetchCalendarConnections = async () => {
      try {
        console.log("Fetching calendar connections...");
        const response = await axiosInstance.post("/graphql", {
          query: `
            {
              getCalendarConnections {
                id
                provider
                email
                isDefault
                connected
                lastSynced
              }
            }
          `,
        });

        console.log("Raw response:", response);

        // Check for errors first
        if (response?.data?.errors) {
          throw new Error(response.data.errors[0].message);
        }

        // Access the data - note the correct path to getCalendarConnections
        const connections = response?.data?.getCalendarConnections || [];
        console.log("Parsed calendar connections:", connections);

        if (connections.length > 0) {
          setCalendarConnections(connections);
          console.log("Calendar connections set:", connections);
        } else {
          console.log("No calendar connections found");
          setCalendarConnections([]);
        }
      } catch (error) {
        console.error("Error fetching calendar connections:", error);
        setAlert({
          open: true,
          message:
            "Failed to fetch calendar connections: " +
            (error.message || "Unknown error"),
          severity: "error",
        });
        setCalendarConnections([]); // Set empty array on error
      } finally {
        setLoading(false);
      }
    };

    fetchCalendarConnections();
  }, []);
  const formik = useFormik({
    initialValues: {
      name: "",
      startDate: null,
      endDate: null,
      startTime: null,
      endTime: null,
      timezone: "Asia/Kolkata",
      about: "",
      organizedBy: "",
      supportEmail: "",
      calendar: "google",
    },
    validationSchema,
    onSubmit: async (values, { setErrors, setStatus, setSubmitting }) => {
      try {
        const {
          name,
          startDate,
          endDate,
          startTime,
          endTime,
          timezone,
          about,
          organizedBy,
          supportEmail,
        } = values;

        // Axios request to GraphQL API
        const eventResponse = await axiosInstance.post("/graphql", {
          query: CREATE_EVENT_MUTATION,
          variables: {
            input: {
              name,
              startDate,
              endDate,
              startTime,
              endTime,
              timezone,
              about,
              organizedBy,
              supportEmail,
            },
          },
        });

        if (eventResponse.data.errors) {
          throw new Error(eventResponse.data.errors[0].message);
        }

        const eventId = eventResponse.data.createEvent.id;

        // Create default types
        const defaultTypes = ["Host", "Co Host", "Speaker"];

        await Promise.all(
          defaultTypes.map((typeName) =>
            axiosInstance.post("/graphql", {
              query: CREATE_TYPE_MUTATION,
              variables: {
                input: {
                  name: typeName,
                  eventId,
                },
              },
            })
          )
        );

        // Find default calendar connection
        const defaultConnection = calendarConnections.find(
          (conn) => conn.isDefault
        );
        console.log("&&&&&&&&&&&&&&&&&", defaultConnection);
        if (defaultConnection) {
          // Sync with default calendar
          const syncResponse = await axiosInstance.post("/graphql", {
            query: SYNC_CALENDAR_EVENT,
            variables: {
              eventId,
              connectionId: defaultConnection.id,
            },
          });

          console.log("kkkkkkkkkkkkkk", syncResponse);

          if (syncResponse.data.errors) {
            console.error("Calendar sync error:", syncResponse.data.errors);
          }
        }

        navigate("/events/events", {
          state: {
            message: "Event Created Successfully!",
            severity: "success",
          },
        });
      } catch (error) {
        console.log("@@@@@@@@@@@@@@@@@ new event error", error);
        const errorMessage =
          error.response?.data?.message || "An unexpected error occurred";
        setAlert({
          open: true,
          message: errorMessage,
          severity: "error",
        });
        setStatus({ success: false });
        setErrors({ submit: errorMessage });
      } finally {
        setSubmitting(false);
      }
    },
  });

  return (
    <MuiCard>
      <CardContent>
        <form onSubmit={formik.handleSubmit}>
          <Typography variant="h6" gutterBottom>
            Basic Information
          </Typography>

          <Grid container spacing={3}>
            <Grid item xs={12}>
              <MuiTextField
                label="Event Name"
                name="name"
                variant="outlined"
                fullWidth
                value={formik.values.name}
                onChange={formik.handleChange}
                error={formik.touched.name && Boolean(formik.errors.name)}
                helperText={formik.touched.name && formik.errors.name}
              />
            </Grid>

            <Grid item xs={12} sm={6}>
              <DatePicker
                label="Start Date"
                value={formik.values.startDate}
                onChange={(value) => formik.setFieldValue("startDate", value)}
                slotProps={{
                  textField: {
                    fullWidth: true,
                    error:
                      formik.touched.startDate &&
                      Boolean(formik.errors.startDate),
                    helperText:
                      formik.touched.startDate && formik.errors.startDate,
                  },
                }}
              />
            </Grid>

            <Grid item xs={12} sm={6}>
              <DatePicker
                label="End Date"
                value={formik.values.endDate}
                onChange={(value) => formik.setFieldValue("endDate", value)}
                slotProps={{
                  textField: {
                    fullWidth: true,
                    error:
                      formik.touched.endDate && Boolean(formik.errors.endDate),
                    helperText: formik.touched.endDate && formik.errors.endDate,
                  },
                }}
              />
            </Grid>

            <Grid item xs={12} sm={6}>
              <TimePicker
                label="Start Time"
                value={formik.values.startTime}
                onChange={(value) => formik.setFieldValue("startTime", value)}
                slotProps={{
                  textField: {
                    fullWidth: true,
                    error:
                      formik.touched.startTime &&
                      Boolean(formik.errors.startTime),
                    helperText:
                      formik.touched.startTime && formik.errors.startTime,
                  },
                }}
              />
            </Grid>

            <Grid item xs={12} sm={6}>
              <TimePicker
                label="End Time"
                value={formik.values.endTime}
                onChange={(value) => formik.setFieldValue("endTime", value)}
                slotProps={{
                  textField: {
                    fullWidth: true,
                    error:
                      formik.touched.endTime && Boolean(formik.errors.endTime),
                    helperText: formik.touched.endTime && formik.errors.endTime,
                  },
                }}
              />
            </Grid>

            <Grid item xs={12}>
              <FormControl required fullWidth variant="outlined">
                <InputLabel>Timezone</InputLabel>
                <Select
                  label="Timezone"
                  name="timezone"
                  value={formik.values.timezone}
                  onChange={formik.handleChange}
                  error={
                    formik.touched.timezone && Boolean(formik.errors.timezone)
                  }
                  helperText={formik.touched.timezone && formik.errors.timezone}
                >
                  {timezones.map((zone) => (
                    <MenuItem key={zone.value} value={zone.value}>
                      {zone.label}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>

            <Grid item xs={12}>
              <Typography variant="body2" gutterBottom>
                About the event
              </Typography>
              <ReactQuill
                theme="snow"
                value={formik.values.about}
                onChange={(value) => formik.setFieldValue("about", value)}
                style={{ height: "150px", marginBottom: "40px" }}
              />
              {formik.touched.about && formik.errors.about && (
                <Typography color="error" variant="caption">
                  {formik.errors.about}
                </Typography>
              )}
            </Grid>

            <Grid item xs={12}>
              <MuiTextField
                label="Organized By"
                name="organizedBy"
                variant="outlined"
                fullWidth
                value={formik.values.organizedBy}
                onChange={formik.handleChange}
                error={
                  formik.touched.organizedBy &&
                  Boolean(formik.errors.organizedBy)
                }
                helperText={
                  formik.touched.organizedBy && formik.errors.organizedBy
                }
              />
            </Grid>

            <Grid item xs={12}>
              <MuiTextField
                label="Support Email"
                name="supportEmail"
                variant="outlined"
                fullWidth
                value={formik.values.supportEmail}
                onChange={formik.handleChange}
                error={
                  formik.touched.supportEmail &&
                  Boolean(formik.errors.supportEmail)
                }
                helperText={
                  formik.touched.supportEmail && formik.errors.supportEmail
                }
              />
            </Grid>

            <Grid item xs={12}>
              <FormControl required fullWidth variant="outlined">
                <InputLabel>Calendar Sync</InputLabel>
                <Select
                  label="Calendar"
                  name="calendar"
                  value={formik.values.calendar}
                  onChange={formik.handleChange}
                  error={
                    formik.touched.calendar && Boolean(formik.errors.calendar)
                  }
                  helperText={formik.touched.calendar && formik.errors.calendar}
                >
                  <MenuItem key="1" value="google">
                    Google
                  </MenuItem>
                  <MenuItem key="1" value="microsoft">
                    Microsoft
                  </MenuItem>
                </Select>
              </FormControl>
            </Grid>

            <Grid item xs={12}>
              <Button
                color="primary"
                variant="contained"
                size="small"
                type="submit"
              >
                Create Event
              </Button>
            </Grid>
          </Grid>
        </form>
      </CardContent>

      {/* Alert Notification */}
      <Snackbar
        open={alert.open}
        autoHideDuration={6000}
        onClose={() => setAlert({ ...alert, open: false })}
      >
        <Alert
          onClose={() => setAlert({ ...alert, open: false })}
          severity={alert.severity}
        >
          {alert.message}
        </Alert>
      </Snackbar>
    </MuiCard>
  );
};

const EventAdd = () => {
  return (
    <React.Fragment>
      <Helmet title="Add New Event" />
      <Grid container justifyContent="space-between" spacing={10}>
        <Grid item>
          <Typography variant="h3" gutterBottom display="inline">
            Add New Event
          </Typography>
          <Breadcrumbs aria-label="Breadcrumb" mt={2}>
            <Link component={NavLink} to="/events/dashboard">
              Dashboard
            </Link>
            <Link component={NavLink} to="/events/events">
              Events List
            </Link>
            <Typography>Add New Event</Typography>
          </Breadcrumbs>
        </Grid>
        <Grid item>
          <div>
            <Button
              variant="contained"
              color="inherit"
              sx={{ mx: "0.5rem" }}
              component={Link}
              to="/events/events"
            >
              <ArrowBackIcon />
              Back
            </Button>
          </div>
        </Grid>
      </Grid>
      <Divider my={6} />
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <EventEntryForm />
        </Grid>
      </Grid>
    </React.Fragment>
  );
};

export default EventAdd;
